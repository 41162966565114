import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import "slick-carousel/slick/slick.css"
import "../../styles/pages/contact-lenses.scss"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CartContext from "../../context/cartContext"
import ProductSlider from "../../components/ProductSlider"
import VideoModule from "../../components/VideoModule"
import ReviewCarousel from "../../components/ReviewCarousel"
import ProductSectionAdvantages from "../../components/ProductSectionAdvantages"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import AboutProductSection from "../../components/AboutProductSection"
import Breadcrumbs from "../../components/Breadcrumbs"
import ProductValueProps from "../../components/ProductValueProps"
import {
  colorTags,
  hydroFaq,
  hubbleValueProps,
  hubbleDifference,
  contactsRelatedProducts,
  hydroAdvantages, getHubblePricing
} from "../../services/data"
import ProductWithHubble from "../../components/ProductWithHubble"
import FAQBlock from "../../components/FAQBlock"
import ProductDetails from "../../components/ProductDetails"
import OrderFlow from "../../components/OrderFlow"
import NewButton from "../../components/NewButton"
import RelatedFrames from "../../components/RelatedFrames"
import { StaticImage } from "gatsby-plugin-image"
import ContactsDetailsMobile from "../../components/ContactsDetailsMobile"
import NewRxFlow from "../../components/NewRxFlow"
import useNewRxFlow from "../../utils/useNewRxFlow"
import useNewRxFlowDesktop from "../../utils/useNewRxFlowDesktop";
import avgProducts from "../../services/avg-products.json";
import {hubbleList} from "../../components/ContactsPrescription/mockedData";
import {productInfo} from "../../components/Intake/Prescription/mockedData";
import usePromo from "../../utils/usePromo";
import Banner from "../../components/Banner";

const HydroLenses = () => {
  const [slides, setSlides] = useState([
    "Pages/ContactsPDP/Product-Images/Hydro-by-Hubble-PDP-two-boxes-01.png",
    "Pages/ContactsPDP/Product-Images/Hydro-by-Hubble-PDP-one-box-02.png",
    "Pages/ContactsPDP/Product-Images/Hydro-by-Hubble-PDP-open-box-03.png",
    "Pages/ContactsPDP/Product-Images/Hydro-by-Hubble-PDP-open-box-04.png",
  ])

  const [show, setShow] = useState(false)
  const { cartItems, createOrder, setHeaderColor, clearOrder, setIsHydro, currentItem, order } = useContext(
    CartContext
  )
  const isNewRx = useNewRxFlow()
  const isNewRxDesktop = useNewRxFlowDesktop()
  const isPromo = usePromo()
  const hubblePricing = getHubblePricing()

  const bulletPoints = [
    ...(!isNewRxDesktop ? ["FDA-approved contact lenses"] : []),
    "Single vision soft contact lenses made from Hioxifilcon A material",
    "Contains 57% water content - made from poly (HEMA-GMA) that naturally attracts and bonds with water molecules to enhance moisture retention",
    "Designed for easy insertion, all-day comfort, and crystal-clear vision",
    "The slimmest contact lens packaging in the world: less waste, less plastic, more convenient",
    "The unique hygienic touch packaging always places the lens correctly face down, allowing the wearer to only touch the outer lens surface for a quicker, more hygienic experience.",
    "The ultra-thin edges minimize eyelid friction and improve comfort."
  ]

  const hubble_lenses = cartItems.filter(obj => {
    return obj.type === "contacts"
  })

  const item = {
    title: "Hydro by Hubble Contact Lenses",
    subheader: "Naturally hydrating daily contact lenses with innovative packaging. With high water content, Hydro by Hubble daily disposable contact lenses provide all-day comfort for dry eyes.",
    price: Number(hubblePricing.hydro.subPrice),
    offPrice: Number(hubblePricing.hydro.firstPrice),
    percentOff: Number(hubblePricing.hydro.percentOff),
    baseCurve: 8.4,
    diameter: 14.2,
    material: "Hioxifilcon A",
    manufacturer: "Menicon",
    id: 1,
  }

  const type = "contacts"

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem("previousPage")
      const urlParams = new URLSearchParams(window?.location.search)
      const rxflow = urlParams.get("rxflow")
      const newRxFlow = urlParams.get("newRxFlow")
      if ((rxflow === "true" || newRxFlow === "true") && (isNewRx || isNewRxDesktop) && order?.prescription?.leftEye?.power && order?.prescription?.rightEye?.power) {
        setShow(true)
      }


      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_id: 7546170802376,
              item_name: 'Hydro by Hubble Contact Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Hioxifilcon A',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 42670112178376,
              price: 9.99,
              quantity: 1,
              index: 0
            }
          ]
        }
      })
    }



    setIsHydro(true)
  }, [])

  const createNewOrder = () => {
    const newItem = {
      id: '',
      type
    }

    const newOrder = {
      email: "",
      state: "",
      doctor: "",
      prescription: {
        rightEye: {
          power: "",
          baseCurve: "8.4",
          diameter: "14.2",
        },
        leftEye: {
          power: "",
          baseCurve: "8.4",
          diameter: "14.2",
        },
      },
      isOneEye: false,
    }

    setHeaderColor("purple")
    if (!isNewRx && !currentItem) {
      createOrder(newItem, newOrder)
    }
  }

  useEffect(() => {
    createNewOrder()

    return () => {
      setHeaderColor("black")
      // if (!isNewRx && !currentItem) clearOrder()
    }
  }, [cartItems, isNewRx])


  const schemaMarkup = [
    {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": "Hydro by Hubble Contact Lenses",
      "image": "/static/Hydro-by-Hubble-PDP-two-boxes-01",
      "description": "Designed for comfort, naturally hydrating contact lenses. Get your first order for $9.99/eye. After that a 28-day supply is just $24.99/eye. Delivered right to your door every month.",
      "brand": {
        "@type": "Thing",
        "name": "Hubble"
      },
      "logo": "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg?v=14192704095517354305",
      "offers": [
        {
          "@type": "Offer",
          "url": "https://hubblecontacts.com/contact-lenses/hydro-by-hubble-lenses/",
          "availability": "https://schema.org/InStock",
          "price": "9.99",
          "priceCurrency": "USD",
          "priceValidUntil": "2023-12-31"
        }
      ]
    },
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts How We Make Ordering Hydrating Contact Lenses Online Easy",
      "description": "At Hubble, we believe ordering our best hydrating contact lenses online should be easy. That’s why we’ve made Hubble’s personalized contact lens subscription service the easiest contacts experience in the world. You can easily order hydrating contacts in your contact lens prescription online. Order Hydro by Hubble contacts and start your subscription to our best daily hydrating prescription contact lenses.",
    "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What is the difference between Hydro by Hubble and Hubble’s classic lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Hubble Hydro contact lenses are made from Hioxifilcon A and feature a higher water content. The materials mimic and bind with natural tears, designed to lock in moisture and quickly rehydrate your eyes. Plus, our hygienic touch technology packaging ensures minimal contact with your hydrating contact lenses and is the world’s slimmest. All of Hubble’s affordable daily contact lenses are FDA-approved, affordable and designed for easy insertion and all-day comfort. Compare each of Hubble’s prescription contacts and learn more about which customizable subscription option is right for you."
          }
        },
        {
          "@type": "Question",
          "name": "Are Hubble hydrating contact lenses FDA-approved?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, our Hubble hydrating contact lenses, the Hydro by Hubble contact lenses, have been FDA approved for sale in the US for over a decade."
          }
        },
        {
          "@type": "Question",
          "name": "Can teenagers wear Hubble’s best hydrating contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, all patients ages 13+, who have a valid contact lens prescription, can wear Hubble daily hydrating contact lenses. Please note: patients under the age of eighteen must receive parental permission before placing an order for our best hydrating contact lenses online with Hubble."
          }
        },
        {
          "@type": "Question",
          "name": "Does Hubble sell bi-weekly or monthly contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Hubble only sells daily lenses because wearing new hydrating contact lenses every day is the safest and most comfortable way to wear contacts."
          }
        },
        {
          "@type": "Question",
          "name": "Can I use water or contact lens solution to clean or store my Hydro by Hubble contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No! Hydro by Hubble lenses are daily contact lenses, designed for individual use. Please do not attempt to wear our prescription hydrating contact lenses for more than one day."
          }
        }
      ]
    },
    {
      "@context" : "https://schema.org",
      "@url" : "https://www.hubblecontacts.com/contact-lenses/hydro-by-hubble-lenses",
      "@type" : "webpage",
      "name" : "Hubble Contacts Hydro Lenses: Naturally Hydrating Contact Lens Subscription",
      "description" : "Hydro by Hubble lenses are moisture-retaining and designed for all-day comfort. 40% off for new customers. Start your automatic subscription and never run out of our best hydrating contact lenses.",
      "publisher" :
      {
      "@type" : "Organization",
      "name" : "Hubble"
      }
      }

  ]

  const handleGaClick = () => {
    if (isNewRx || isNewRxDesktop) {
      navigate(`/contact-lenses/hydro-by-hubble-lenses/?newRxFlow=true`)
    }

    createNewOrder()

    setShow(true)
    if (typeof window !== "undefined") {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_id: 7546170802376,
              item_name: 'Hydro by Hubble Contact Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Hioxifilcon A',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 42670112178376,
              price: 9.99,
              quantity: 1,
              index: 0
            }
          ]
        }
      });
    }
  }

  const validateBrandPrescription = (item, prescription) => {
    const hubbleBrand =
      item.title.toLowerCase().includes("hubble classic") && "default" ||
      item.title.toLowerCase().includes("skyhy by") && "skyhy" ||
      item.title.toLowerCase().includes("hydro by") && "hydro"
    const prescriptionSettings = {...productInfo[hubbleBrand || item.cc_product_id]};
    prescriptionSettings.eye_powers = [
      { value: 'oneEye', label: `` },
      ...prescriptionSettings.eye_powers
    ]
    const eyes = [prescription.leftEye, prescription.rightEye]

    if (eyes[0].power === "oneEye" && eyes[1].power === "oneEye") return false;

    return eyes.every(eye => {
      if (eye.power === "oneEye") return true;

      return Object.entries(eye).every(([key, value]) => {
        let customKeys = {
          "power": "eye_powers",
          "addPower": "add_power",
          "baseCurve": "base_curve_values"
        }

        let _key = customKeys[key] || key;

        if (key === "diameter" || key === "baseCurve" && (
          !Array.isArray(prescriptionSettings[_key]) ||
          Array.isArray(prescriptionSettings[_key]) && prescriptionSettings[_key].length < 2)
        )
          return true

        if (typeof prescriptionSettings[_key] === "object")
          if (typeof prescriptionSettings[_key][0] === "object") {
            return prescriptionSettings[_key].some(({value: settingsValue}) => settingsValue == value)
          } else {
            return prescriptionSettings[_key].some((settingsValue) => settingsValue == value)
          }
        else
          return prescriptionSettings[_key] == value
      }) && Object.keys(prescriptionSettings).every(key => {
        let customKeys = {
          "eye_powers": "power",
          "add_power": "addPower",
          "base_curve_values": "baseCurve"
        }

        let _key = customKeys[key] || key;

        return !!eye[_key]
      })
    })
  }

  return (
    <Layout className="hubble-lenses">
      <SEO
        title="Order Hydro by Hubble Hydrating Daily Contact Lenses Online | Hubble Contacts"
        description="Hydro by Hubble lenses are moisture-retaining and the best daily contacts for hydrating dry eyes. Get 40% off your first order. Shop now, start your personalized subscription, and never run out of contacts."
        schemaMarkup={schemaMarkup} />
      <Banner
        bannerClass="purple discount-banner promo-banner pcp extra-padding"
        bannerText="Get your first order of SkyHy or Hydro by Hubble for a special reduced price."
      />
      {isNewRx ?
        <ContactsDetailsMobile item={item} type={type} slides={slides} handleGaClick={handleGaClick} lenses={hubble_lenses} isHydro={true} tagText='Popular Choice' tagColor='purple' /> :
        <div className="top-section ">
          <NewButton
            label={"Select lenses"}
            color={"purple"}
            onClick={() => handleGaClick()}
            disabled={hubble_lenses?.length !== 0}
            className={'mobile-only'}
            stickyScroll={true} />
          <ProductSlider slides={slides} type={type} product={item} containImage centerImage tagText='Popular Choice' tagColor='purple' />
          <ProductDetails item={item} type={type} hydro={true} onClick={() => handleGaClick()} />
        </div>
      }
      <ProductValueProps props={hubbleValueProps()} type={type} />
      <div className="product-info">
        <div className="grid-section">
          <Breadcrumbs
            links={[
              { to: "/", label: "Homepage" },
              { to: "/contact-lenses/hubble", label: "Hubble Contact Lenses" },
              { label: "Hydro Contact Lenses by Hubble" },
            ]}
          />
        </div>
        {!isNewRx &&
          <>
            <AboutProductSection
              title="Hubble Lenses"
              type={type}
              heading="Naturally Hydrating Contacts for Dry Eyes"
              description={`See things from a new perspective with Hydro by Hubble contact lenses. With their smooth edges and moisture-retaining materials, these hydrating lenses are designed for comfort.`}
              bullet_points={bulletPoints}
            />
            <ProductSectionAdvantages list={hydroAdvantages} />
          </>
        }

        <HowHubbleWorksCarousel color={colorTags[type]} />
        <div className="grid-section lifestyle-shot">
          <div className="desktop-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image.jpg"
              alt="Cheerful woman putting in her Hydro by Hubble hydrating contact lens"
            />
          </div>
          <div className="mobile-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image-Mobile.jpg"
              alt="Cheerful woman putting in her Hydro by Hubble hydrating contact lens"
            />
          </div>
        </div>
        <ProductWithHubble type={type} items={hubbleDifference} />
        <VideoModule
          type={type}
          video={"https://www.youtube.com/embed/NKxNYzCRGwg"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing her hydrating contact lenses from a Hubble online order"
        />
        {isNewRx &&
          <AboutProductSection
          title="Hubble Lenses"
          type={type}
          heading="Naturally Hydrating Contacts for Dry Eyes"
          description={`See things from a new perspective with Hydro by Hubble contact lenses. With their smooth edges and moisture-retaining materials, these hydrating lenses are designed for comfort.`}
          bullet_points={bulletPoints.filter((item) => !item.toLowerCase().includes('fda'))}
          />}
          {/* <div id="reviews"></div> */}
          {/* <ReviewCarousel color={colorTags[type]} page="contacts" /> */}
        {isNewRx && <ProductSectionAdvantages list={hydroAdvantages} />}
          <FAQBlock faqList={hydroFaq} color={colorTags[type]} />
        </div>
        {isNewRx || isNewRxDesktop ?
          <NewRxFlow type={type} show={show} setShow={setShow} brand='hydro' onHide={() => setShow(false)}
                     validateBrandPrescription={validateBrandPrescription}
                     optionsList={[{options: [...avgProducts.flatMap(e => e.options), {
                         id: -1,
                         title: "Browse 50+ more brands at contactscart.com",
                         price: "",
                         value: -1,
                         link: "https://www.contactscart.com/",
                       }, ...hubbleList(hubblePricing)]}]}/> :
          <OrderFlow type={type} show={show} setShow={setShow} brand='hydro' />}
        <RelatedFrames
          relatedFrames={contactsRelatedProducts}
          type={type}
          linkTo="/contact-lenses"
        />
    </Layout>
  )
}
export default HydroLenses
